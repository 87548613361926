import Origo1 from "./images/Origo/Origo_1.jpeg";
import Origo2 from "./images/Origo/Origo_2.jpeg";
import Origo3 from "./images/Origo/Origo_3.jpeg";
import Origo4 from "./images/Origo/Origo_4.jpeg";
import Origo5 from "./images/Origo/Origo_5.jpeg";
import Origo6 from "./images/Origo/Origo_6.jpeg";
import Origo7 from "./images/Origo/Origo_7.jpeg";
import Origo8 from "./images/Origo/Origo_8.jpeg";
import Origo9 from "./images/Origo/Origo_9.jpeg";
import Origo10 from "./images/Origo/Origo_10.jpeg";
import Origo11 from "./images/Origo/Origo_11.jpeg";
import Origo12 from "./images/Origo/Origo_12.jpeg";
import Hornet1 from "./images/Hornet/Hornet_1.jpg";
import Hornet2 from "./images/Hornet/Hornet_2.jpg";
import Hornet3 from "./images/Hornet/Hornet_3.jpg";
import Hornet4 from "./images/Hornet/Hornet_4.jpg";
import Hornet5 from "./images/Hornet/Hornet_5.jpg";
import Hornet6 from "./images/Hornet/Hornet_6.jpg";
import Hornet7 from "./images/Hornet/Hornet_7.jpg";
import Hornet8 from "./images/Hornet/Hornet_8.jpg";
import Hornet9 from "./images/Hornet/Hornet_9.jpg";
import Kasernhus1 from "./images/Kasernhus/Kasernhus_1.jpg";
import Kasernhus2 from "./images/Kasernhus/Kasernhus_2.jpg";
import Kasernhus3 from "./images/Kasernhus/Kasernhus_3.jpg";
import Kasernhus4 from "./images/Kasernhus/Kasernhus_4.jpg";
import Kasernhus5 from "./images/Kasernhus/Kasernhus_5.jpg";
import Kasernhus6 from "./images/Kasernhus/Kasernhus_6.jpg";
import Kasernhus7 from "./images/Kasernhus/Kasernhus_7.jpg";
import Kasernhus8 from "./images/Kasernhus/Kasernhus_8.jpg";
import Kasernhus9 from "./images/Kasernhus/Kasernhus_9.jpg";
import Kasernhus10 from "./images/Kasernhus/Kasernhus_10.jpg";
import Mjolby1 from "./images/Mjolby/Mjolby_1.jpg";
import Mjolby2 from "./images/Mjolby/Mjolby_2.jpg";
import Mjolby3 from "./images/Mjolby/Mjolby_3.jpg";
import Mjolby4 from "./images/Mjolby/Mjolby_4.jpg";
import Mjolby5 from "./images/Mjolby/Mjolby_5.jpg";
import Mjolby6 from "./images/Mjolby/Mjolby_6.jpg";
import Mjolby7 from "./images/Mjolby/Mjolby_7.jpg";
import Mjolby8 from "./images/Mjolby/Mjolby_8.jpg";
import Mjolby9 from "./images/Mjolby/Mjolby_9.jpg";
import Mjolby10 from "./images/Mjolby/Mjolby_10.jpg";
import Datacenter from "./images/datacenter_ai.webp";
import {
  faCogs,
  faLeaf,
  faAward,
  faHandshakeSimple,
} from "@fortawesome/free-solid-svg-icons";

export const dataEnglish = {
  header: {
    about: "About us",
    contact: "Contact us",
    projects: "Projects",
    home: "Home",
    vacancies: "Vacancies",
    language: {
      english: {
        name: "English",
        img: "../images/EU-flag.png",
      },
      svenska: {
        name: "Svenska",
        img: "../images/SE-flag.png",
      },
    },
  },
  about: {
    title1: "About Us",
    title2: "Our values",
    subtitle1: "PreFab",
    subtitle2: "Concrete",
    description1:
      "We are a team of experienced construction professionals committed to achieving the best results in all we do.",
    description2:
      "We keep strong focus on all phases of the work starting from the very first - careful examination of drawings and finding the most effective way to achieve best workflow eliminating any delay of the project as it is one of the most crucial part of having a successful project.",
    values:
      "With strong focus on lean construction management, we believe that each project has a place for improvement both in management and sustainability besides a great quality and customer satisfaction ",
    valuesSpan: "per se.",
    valuesList: [
      {
        icon: faCogs,
        text: "Lean Construction",
        description:
          "Efficiency at every stage, reducing waste and maximizing resources.",
      },
      {
        icon: faLeaf,
        text: "Sustainability",
        description:
          "We build for today, while thinking of tomorrow's environment.",
      },
      {
        icon: faAward,
        text: "Quality",
        description: "Superior craftsmanship is in the details.",
      },
      {
        icon: faHandshakeSimple,
        text: "Collaboration",
        description:
          "A partnership built on trust, communication, and reliability.",
      },
    ],
    weofferTitle: ["concrete", "&", "prefab"],
    prefab:
      "Prefabricated constructions are now seen almost everywhere, marking a major leap toward lean construction by minimizing time spent on-site. For new owners and tenants, that means faster move-in dates. Our expert team is ready to skillfully assemble precast concrete elements, CLT structures, or prefabricated private homes.",
    concrete:
      "For over 150 years, cast-in-place steel-reinforced concrete has been essential in construction, from foundations to multi-story buildings. Our team handles all types of structures, from simple foundations for homes to large-scale projects. No matter the size, we deliver with confidence.",
    plasterboard:
      "There is almost no construction project carried out without plasterboards nowadays. That is also one of almost no-waste processes if calculated and carried out correctly. We offer a team of skilled carpenters to build inner walls after casting or assembly of concrete walls. Or if just a team of carpenters is needed we can help.",
  },

  projects: {
    title: "Projects",
    list: [
      {
        id: 1,
        title: "Residential building in Eskilstuna",
        description:
          "2550m² concrete foundation, 115m on-site casted walls, 290m³ concrete casted, 45t reinforcement tied",
        image: [
          Origo1,
          Origo2,
          Origo3,
          Origo4,
          Origo5,
          Origo6,
          Origo7,
          Origo8,
          Origo9,
          Origo10,
          Origo11,
          Origo12,
        ],
        year: 2022,
      },
      {
        id: 2,
        title: "Villa in Flen",
        description:
          "1065m² reinforced slab, 110m on-site casted walls, 268m³ concrete casted, 18t reinforcement tied",
        image: [
          Hornet1,
          Hornet2,
          Hornet3,
          Hornet4,
          Hornet5,
          Hornet6,
          Hornet7,
          Hornet8,
          Hornet9,
        ],
        year: 2022,
      },
      {
        id: 3,
        title: "Residential building in Strängnäs",
        description:
          "860m² reinforced bottom plate, 300m drainage pipe, 263m radon pipe,  320m³ gravel pumped, 160m³ concrete casted, 25t reinforcement tied",
        image: [
          Kasernhus1,
          Kasernhus2,
          Kasernhus3,
          Kasernhus4,
          Kasernhus5,
          Kasernhus6,
          Kasernhus7,
          Kasernhus8,
          Kasernhus9,
          Kasernhus10,
        ],
        year: 2022,
      },
      {
        id: 4,
        title: "Hotel in Mjolby: Paddel hall",
        description:
          "816m² reinforced slab, 314m on-site casted walls, 318m³ concrete casted, 40t reinforcement tied",
        image: [
          Mjolby1,
          Mjolby2,
          Mjolby3,
          Mjolby4,
          Mjolby5,
          Mjolby6,
          Mjolby7,
          Mjolby8,
          Mjolby9,
          Mjolby10,
        ],
        year: 2022,
      },
      {
        id: 5,
        title: "Data Centers",
        description:
          "Work in progress. 14 130m³ concrete and 1500t reinforcement in each.",
        image: [Datacenter],
        year: 2024,
      },
    ],
  },
  contact: {
    title: "Contact Us",
    text1: "Alternatively you can contact us directly via: ",
    phone: "+46 (0) 703-52 19 19",
    contactEmail: " info@andrama.se",
    text2: "Or write us to our social accounts: ",
    fullName: "FULL NAME",
    email: "YOUR EMAIL",
    message: "MESSAGE...",
    submit: "SUBMIT",
  },
  vacancies: {
    lv: {
      title: "Vakances",
      buttonTitle: "Lasīt vairāk",
      positions: [
        {
          id: 1,
          positionTitle:
            "Būvdarbu vadītājs darbam būvniecības objektos Zviedrijā ",
          intro:
            "Būvniecības uzņēmums Andrama Bygg saistībā ar darba apjoma pieaugumu, aicina pievienoties savai komandai būvdarbu vadītāju.",
          requirements: {
            sectionTitle: "Prasības kandidātiem:",
            list: [
              "Pieredze būvdarbu vadīšanā, vēlams Skandināvijā – Zviedrijā vai Norvēģijā. ",
              "MS Office datorzināšanas labā līmenī, AutoCAD zināšanas tiks uzskatītas par priekšrocību.",
              "Labas saskarsmes un komunikācijas prasmes, precizitāte.",
              "Angļu valodas prasmes B1 līmenī.",
              "B kategorijas autovadītāja apliecība.",
            ],
          },
          responsibilities: {
            sectionTitle: "Darba pienākumi:",
            list: [
              "Būvniecības projektu plānošana, organizēšana un uzraudzība.",
              "Būvmateriālu apjomu kalkulēšana un pasūtīšana.",
              "Darbu plānošana objektā starp vairākām būvniecības komandām.",
              "Būvniecības kvalitātes uzraudzība un atbilstības nodrošināšana projekta prasībām un normatīviem aktiem.",
              "Izmaksu uzraudzība un projekta finanšu kontroles nodrošināšana.",
              "Darba plānu izstrāde un to izpildes uzraudzība, darba stundu uzskaite.",
              "Drošības standartu ievērošana un kontrole būvlaukumā.",
              "Problēmu noteikšana un efektīva risinājumu izstrāde projekta norises laikā.",
              "Atskaišu izstrāde un uzturēšana.",
            ],
          },
          offers: {
            sectionTitle: "Uzņēmums piedāvā: ",
            list: [
              "Konkurētspējīgu atalgojumu, atbilstoši darba pieredzei un kvalifikācijai, sākot no 2500Eur bruto.",
              "Visus darbam nepieciešamos instrumentus, darba apģērbus un individuālos aizsardzības līdzekļus, piemērotus darba apstākļiem.",
              "Apmaksātu dzīves vietu Zviedrijā.",
              "Apmaksātus ceļošanas izdevumus. ",
              "Nepieciešamās dokumentācijas sagatavošana.",
              "Darbs saskaņā ar rotācijas grafiku – 3 nedēļas darbs objektā, 1 nedēļa atpūta.",
            ],
          },
          endNote:
            "Interesentus lūgums aizpildīt pieteikuma anketu vai sūtīt pieteikumu ar savu CV uz e-pasta adresi job@andrama.se.",
        },
        {
          id: 2,
          positionTitle:
            "Betonētājs/Veidņotājs darbam būvniecības objektos Zviedrijā",
          intro:
            "Būvniecības uzņēmums Andrama Bygg saistībā ar darba apjoma pieaugumu, aicina pievienoties savai komandai betonētāju/veidņotāju.",

          requirements: {
            sectionTitle: "Prasības kandidātiem:",
            list: [
              "Darba pieredze veidņošanas darbos (DOKA, PERI, finieris) un betona liešanā.",
              "Zināšanas par betona kopšanu atbilstoši tehnoloģijām.",
              "Vēlama pieredze darbā ar ģeodēzijas mēraparātiem.",
              "Prasmes lasīt rasējumus un strādāt saskaņā ar tiem.",
              "Precizitāte un augsta atbildības sajūta.",
              "Komandas darba prasmes un vēlme sadarboties.",
              "Labas plānošanas prasmes un spēja izpildīt darbu laikā.",
              "Vēlama B kategorijas autovadītāja apliecība.",
            ],
          },
          responsibilities: {
            sectionTitle: "Darba pienākumi:",
            list: [
              "Veikt veidņošanas, betonēšanas un atveidņošanas darbus atbilstoši tehniskajām prasībām.",
              "Garantēt kvalitāti un precizitāti būvniecības darbos.",
              "Uzturēt darba instrumentus un aprīkojumu.",
              "Sadarboties ar citiem komandas locekļiem, lai izpildītu projekta termiņus.",
              "Ievērot drošības standartus un darba apstākļus objektā.",
              "Piedalīties darbu plānošanā un materiālu pasūtīšanā.",
            ],
          },
          offers: {
            sectionTitle: "Uzņēmums piedāvā:",
            list: [
              "Konkurētspējīgu atalgojumu, atbilstoši darba pieredzei un kvalifikācijai, sākot no 12EUR/stundā neto (uz rokas).",
              "Visus darbam nepieciešamos instrumentus, darba apģērbus un individuālos aizsardzības līdzekļus, piemērotus darba apstākļiem.",
              "Apmaksātu dzīves vietu Zviedrijā.",
              "Apmaksātus ceļošanas izdevumus.",
              "Bezmaksas transportu nokļūšanai uz un no objekta.",
              "Nepieciešamās dokumentācijas sagatavošana.",
              "Darbs saskaņā ar rotācijas grafiku – 3 nedēļas darbs objektā, 1 nedēļa atpūta.",
            ],
          },
          endNote:
            "Interesentus lūgums aizpildīt pieteikuma anketu vai sūtīt pieteikumu ar savu CV uz e-pasta adresi: job@andrama.se.",
        },
        {
          id: 3,
          positionTitle: "Stiegrotājs darbam būvniecības objektos Zviedrijā",
          intro:
            "Būvniecības uzņēmums Andrama Bygg saistībā ar darba apjoma pieaugumu, aicina pievienoties savai komandai stiegrotāju.",
          requirements: {
            sectionTitle: "Prasības kandidātiem:",
            list: [
              "Darba pieredze dažādos stiegrošanas darbos.",
              "Prasmes lasīt rasējumus un strādāt saskaņā ar tiem.",
              "Precizitāte un augsta atbildības sajūta.",
              "Prasme strādāt komandā.",
              "Atbildības sajūta par sev uzticētajiem uzdevumiem un to izpildi, spēja organizēt savu darbu un plānot termiņus.",
              "Vēlama B kategorijas autovadītāja apliecība.",
            ],
          },
          responsibilities: {
            sectionTitle: "Darba pienākumi:",
            list: [
              "Garināt un stiegrot atbilstoši rasējumiem.",
              "Garantēt kvalitāti un precizitāti būvniecības darbos.",
              "Uzturēt darba instrumentus un aprīkojumu.",
              "Sadarboties ar citiem komandas locekļiem, lai izpildītu projekta termiņus.",
              "Ievērot drošības standartus objektā.",
              "Piedalīties darbu plānošanā un materiālu pasūtīšanā.",
            ],
          },
          offers: {
            sectionTitle: "Uzņēmums piedāvā:",
            list: [
              "Konkurētspējīgu atalgojumu, atbilstoši darba pieredzei un kvalifikācijai, sākot no 12EUR/stundā neto (uz rokas).",
              "Visus darbam nepieciešamos instrumentus, darba apģērbus un individuālos aizsardzības līdzekļus, piemērotus darba apstākļiem;",
              "Apmaksātu dzīves vietu Zviedrijā;",
              "Apmaksātus ceļošanas izdevumus;",
              "Bezmaksas transportu nokļūšanai uz un no objekta;",
              "Nepieciešamās dokumentācijas sagatavošana;",
              "Darbs saskaņā ar rotācijas grafiku – 3 nedēļas darbs objektā, 1 nedēļa atpūta.",
            ],
          },
          endNote:
            "Interesentus lūgums aizpildīt pieteikuma anketu vai sūtīt pieteikumu ar savu CV uz e-pasta adresi: job@andrama.se.",
        },
      ],
      formData: {
        formTitle: "Pieteikuma anketa",
        fullName: "Vārds, Uzvārds",
        jobPosition: "Vakantā pozīcija",
        email: "E-pasts",
        phone: "Telefons",
        file: "CV Augšupielāde",
        coverLetter: "Motivācijas vēstule",
        submitButton: "Nosūtīt pieteikumu",
      },
    },
    en: {
      title: "Vacant positions",
      buttonTitle: "Read more",
      positions: [
        {
          id: 1,
          positionTitle: "Construction Site Manager in Sweden",
          intro:
            "Andrama Bygg, a growing construction company, is looking for a Construction Site Manager to join our team.",
          requirements: {
            sectionTitle: "Candidate Requirements:",
            list: [
              "Work experience in construction site management, preferably in Scandinavia (Sweden or Norway).",
              "Proficient MS Office computer skills, knowledge of AutoCAD will be considered an advantage.",
              "Good communication and interpersonal skills, precision.",
              "English language skills at B1 level.",
              "Category B driver's license.",
            ],
          },
          responsibilities: {
            sectionTitle: "Job Responsibilities:",
            list: [
              "Planning, organizing, and supervising construction projects.",
              "Calculating and ordering construction materials.",
              "Planning work at the site between several construction teams.",
              "Supervision of construction quality and ensuring compliance with project requirements and regulations.",
              "Cost monitoring and ensuring financial control of the project.",
              "Development and monitoring of work plans, tracking work hours.",
              "Ensuring and controlling safety standards at the construction site.",
              "Identifying problems and developing effective solutions during the project execution.",
              "Developing and maintaining documentation and reports.",
            ],
          },
          offers: {
            sectionTitle: "We offer:",
            list: [
              "Competitive salary according to work experience and qualifications, starting from 2500 EUR gross.",
              "All necessary tools for work, work clothes, and personal protective equipment suitable for working conditions.",
              "Paid accommodation in Sweden.",
              "Paid travel expenses.",
              "Preparation of the necessary documentation.",
              "Work according to a rotation schedule – 3 weeks of work on-site, 1 week of rest.",
            ],
          },
          endNote:
            "Interested candidates are kindly requested to fill out the application form OR send their CV and attachement letter to the email address job@andrama.se.",
        },
        {
          id: 2,
          positionTitle: "Concrete Worker/Formwork Specialist (Sweden)",
          intro:
            "Andrama Bygg, a growing construction company, is looking for a Concrete Worker/Formwork Specialist to join our team.",

          requirements: {
            sectionTitle: "Candidate Requirements:",
            list: [
              "Work experience in formwork (DOKA, PERI, plywood) and concrete pouring.",
              "Knowledge of concrete care according to technologies.",
              "Experience with geodetic measuring instruments is desirable.",
              "Ability to read drawings and work accordingly.",
              "Precision and high sense of responsibility.",
              "Teamwork skills and willingness to cooperate.",
              "Good planning skills and ability to complete work on time.",
              "A category B driver's license is desirable.",
            ],
          },
          responsibilities: {
            sectionTitle: "Job Responsibilities:",
            list: [
              "Perform formwork, concreting, and stripping work in accordance with technical requirements.",
              "Guarantee quality and precision in construction work.",
              "Maintain work tools and equipment.",
              "Collaborate with other team members to meet project deadlines.",
              "Observe safety standards and working conditions on-site.",
              "Participate in work planning and material ordering.",
            ],
          },
          offers: {
            sectionTitle: "We offer:",
            list: [
              "Competitive salary according to work experience and qualifications, starting from 12 EUR/hour net (after taxes).",
              "All necessary tools for work, work clothes, and personal protective equipment suitable for working conditions.",
              "Paid accommodation in Sweden.",
              "Paid travel expenses.",
              "Free transport to and from the site.",
              "Preparation of the necessary documentation.",
              "Work according to a rotation schedule – 3 weeks of work on-site, 1 week of rest.",
            ],
          },
          endNote:
            "Interested candidates are kindly requested to fill out the application form OR send their CV and attachement letter to the email address job@andrama.se.",
        },
        {
          id: 3,
          positionTitle: "Reinforcement Specialist (Sweden)",
          intro:
            "The construction company Andrama Bygg, in response to an increase in workload, invites applications for the position of Reinforcement Specialist.",
          requirements: {
            sectionTitle: "Candidate Requirements:",
            list: [
              "Work experience in various reinforcement tasks.",
              "Ability to read drawings and work accordingly.",
              "Precision and high sense of responsibility.",
              "Teamwork skills.",
              "Responsibility for assigned tasks and their completion, ability to organize one's work and plan deadlines.",
              "A category B driver's license is desirable.",
            ],
          },
          responsibilities: {
            sectionTitle: "Job Responsibilities:",
            list: [
              "Cutting and reinforcing according to drawings.",
              "Guarantee quality and precision in construction work.",
              "Maintain work tools and equipment.",
              "Collaborate with other team members to meet project deadlines.",
              "Observe safety standards on-site.",
              "Participate in work planning and material ordering.",
            ],
          },
          offers: {
            sectionTitle: "We offer:",
            list: [
              "Competitive salary according to work experience and qualifications, starting from 12 EUR/hour net (after taxes).",
              "All necessary tools for work, work clothes, and personal protective equipment suitable for working conditions.",
              "Paid accommodation in Sweden.",
              "Paid travel expenses.",
              "Free transport to and from the site.",
              "Preparation of the necessary documentation.",
              "Work according to a rotation schedule – 3 weeks of work on-site, 1 week of rest.",
            ],
          },
          endNote:
            "Interested candidates are kindly requested to fill out the application form OR send their CV and attachement letter to the email address job@andrama.se.",
        },
      ],
      formData: {
        formTitle: "Application form",
        fullName: "Full name",
        jobPosition: "Job Position",
        email: "Email",
        phone: "Phone",
        file: "CV upload",
        coverLetter: "Cover Letter",
        submitButton: "Submit Application",
      },
    },
    rus: {
      title: "Вакансии",
      buttonTitle: "Читать далее",
      positions: [
        {
          id: 1,
          positionTitle:
            "Руководитель строительных работ для работы на строительных проектах в Швеции",
          intro:
            "Строительная компания Andrama Bygg в связи с увеличением объема работ ищет руководителя строительных работ для усиления своей команды.",
          requirements: {
            sectionTitle: "Требования к кандидату:",
            list: [
              "Опыт работы руководителем строительства, желательно в Скандинавии — в Швеции или Норвегии.",

              "Хорошие знания MS Office, знание AutoCAD будет преимуществом.",

              "Отличные коммуникативные навыки, точность.",

              "Знание английского языка на уровне B1.",

              "Водительские права категории B.",
            ],
          },
          responsibilities: {
            sectionTitle: "Обязанности на должности:",
            list: [
              "Планирование, организация и контроль строительных проектов.",

              "Расчет и заказ строительных материалов.",

              "Организация работ на объекте между несколькими строительными бригадами.",

              "Контроль качества строительства и соблюдение требований и норм проекта.",

              "Контроль расходов и обеспечение финансового контроля над проектом.",

              "Разработка и мониторинг рабочих планов, учет рабочего времени.",

              "Соблюдение и контроль стандартов безопасности на стройке.",

              "Выявление проблем и разработка эффективных решений в процессе проекта.",

              "Разработка и ведение документации и отчетов.",
            ],
          },
          offers: {
            sectionTitle: "Мы предлагаем:",
            list: [
              "Конкурентоспособная зарплата, в зависимости от опыта и квалификации, от 2500 евро брутто.",

              "Все необходимые инструменты, спецодежда и средства индивидуальной защиты, адаптированные к условиям работы.",

              "Оплачиваемое проживание в Швеции.",

              "Оплачиваемые транспортные расходы.",

              "Подготовка необходимой документации.",

              "Работа по ротационному графику — 3 недели на объекте, 1 неделя выходная.",
            ],
          },
          endNote:
            "Заинтересованных кандидатов просим заполнить анкету или отправить резюме на электронную почту job@andrama.se.",
        },
        {
          id: 2,
          positionTitle:
            "Бетонщик/Опалубщик для работы на строительных проектах в Швеции",
          intro:
            "Строительная компания Andrama Bygg в связи с увеличением объема работ ищет бетонщиков/опалубщиков для усиления своей команды.",

          requirements: {
            sectionTitle: "Требования к кандидату:",
            list: [
              "Опыт работы с опалубкой (DOKA, PERI, фанера) и бетонными работами.",

              "Знание ухода за бетоном в соответствии с технологическими требованиями.",

              "Опыт работы с геодезическими приборами будет преимуществом.",

              "Умение читать чертежи и работать по ним.",

              "Точность и высокая ответственность.",

              "Способность работать в команде и желание сотрудничать.",

              "Хорошие навыки планирования и выполнения работ в срок.",

              "Водительские права категории B будут преимуществом.",
            ],
          },
          responsibilities: {
            sectionTitle: "Обязанности на должности:",
            list: [
              "Выполнение опалубочных и бетонных работ в соответствии с техническими требованиями.",

              "Обеспечение качества и точности выполнения строительных работ.",

              "Уход за инструментами и оборудованием.",

              "Сотрудничество с другими членами команды для соблюдения сроков проекта.",

              "Соблюдение стандартов безопасности на стройке.",

              "Участие в планировании работ и заказе материалов.",
            ],
          },
          offers: {
            sectionTitle: "Мы предлагаем:",
            list: [
              "Конкурентоспособная зарплата, в зависимости от опыта и квалификации, от 12 евро/час нетто (на руки).",

              "Все необходимые инструменты, спецодежда и средства индивидуальной защиты, адаптированные к условиям работы.",

              "Оплачиваемое проживание в Швеции.",

              "Оплачиваемые транспортные расходы.",

              "Бесплатный транспорт до и с места работы.",

              "Подготовка необходимой документации.",

              "Работа по ротационному графику — 3 недели на объекте, 1 неделя выходная.",
            ],
          },
          endNote:
            "Заинтересованных кандидатов просим заполнить анкету или отправить резюме на электронную почту job@andrama.se.",
        },
        {
          id: 3,
          positionTitle:
            "Арматурщик для работы на строительных проектах в Швеции",
          intro:
            "Строительная компания Andrama Bygg в связи с увеличением объема работ ищет арматурщиков для усиления своей команды.",
          requirements: {
            sectionTitle: "Требования к кандидату:",
            list: [
              "Опыт работы с различными типами арматурных работ.",

              "Умение читать чертежи и работать по ним.",

              "Точность и высокая ответственность.",

              "Способность работать в команде.",

              "Ответственность за порученные задания и их выполнение, умение организовать свою работу и планировать сроки.",

              "Водительские права категории B будут преимуществом.",
            ],
          },
          responsibilities: {
            sectionTitle: "Обязанности на должности:",
            list: [
              "Выполнение арматурных работ согласно чертежам.",

              "Обеспечение качества и точности выполнения строительных работ.",

              "Уход за инструментами и оборудованием.",

              "Сотрудничество с другими членами команды для соблюдения сроков проекта.",

              "Соблюдение стандартов безопасности на стройке.",

              "Участие в планировании работ и заказе материалов.",
            ],
          },
          offers: {
            sectionTitle: "Мы предлагаем:",
            list: [
              "Конкурентоспособная зарплата, в зависимости от опыта и квалификации, от 12 евро/час нетто (на руки).",

              "Все необходимые инструменты, спецодежда и средства индивидуальной защиты, адаптированные к условиям работы.",

              "Оплачиваемое проживание в Швеции.",

              "Оплачиваемые транспортные расходы.",

              "Бесплатный транспорт до и с места работы.",

              "Подготовка необходимой документации.",

              "Работа по ротационному графику — 3 недели на объекте, 1 неделя выходная.",
            ],
          },
          endNote:
            "Заинтересованных кандидатов просим заполнить анкету или отправить резюме на электронную почту job@andrama.se.",
        },
      ],
      formData: {
        formTitle: "Форма заявки",
        fullName: "Полное имя",
        jobPosition: "Должность",
        email: "Электронная почта",
        phone: "Телефон",
        file: "Загрузка резюме",
        coverLetter: "Сопроводительное письмо",
        submitButton: "Отправить заявку",
      },
    },
    se: {
      title: "Lediga tjänster",
      buttonTitle: "Läs mer",
      positions: [
        {
          id: 1,
          positionTitle: "Byggarbetsledare för arbete på byggprojekt i Sverige",
          intro:
            "Byggföretaget Andrama Bygg, i samband med ökningen av arbetsvolymen, söker att förstärka sitt team med en byggarbetsledare.",
          requirements: {
            sectionTitle: "Kandidats krav:",
            list: [
              "Erfarenhet av byggledning, helst i Skandinavien – Sverige eller Norge.",
              "Bra kunskaper i MS Office, kunskap i AutoCAD är en fördel.",
              "Goda sociala och kommunikationsförmågor, noggrannhet.",
              "Kunskaper i engelska på B1-nivå.",
              "Körkort kategori B.",
            ],
          },
          responsibilities: {
            sectionTitle: "Arbetsuppgifter:",
            list: [
              "Planering, organisering och övervakning av byggprojekt.",

              "Beräkning och beställning av byggmaterial.",

              "Planering av arbete på plats mellan flera byggteam.",

              "Övervakning av byggkvalitet och säkerställande av att projektkraven och föreskrifterna följs.",

              "Kostnadsövervakning och säkerställande av ekonomisk kontroll av projektet.",

              "Utveckling och övervakning av arbetsplaner, tidsregistrering.",

              "Efterlevnad och kontroll av säkerhetsstandarder på byggplatsen.",

              "Identifiering av problem och utveckling av effektiva lösningar under projektets gång.",

              "Utveckling och underhåll av dokumentation och rapporter.",
            ],
          },
          offers: {
            sectionTitle: "Företaget erbjuder:",
            list: [
              "Konkurrenskraftig lön, beroende på erfarenhet och kvalifikationer, från 2500 Euro brutto.",

              "Alla nödvändiga verktyg, arbetskläder och personliga skyddsutrustning för arbetet, anpassade till arbetsförhållanden.",

              "Betald bostad i Sverige.",

              "Betalda reseutgifter.",

              "Förberedelse av nödvändig dokumentation.",

              "Arbete enligt rotationsschema – 3 veckor på plats, 1 vecka ledig.",
            ],
          },
          endNote:
            "Intresserade kandidater ombeds att fylla i ansökningsformuläret eller skicka in sin ansökan tillsammans med ett CV till e-postadressen: job@andrama.se.",
        },
        {
          id: 2,
          positionTitle: "Betongarbetare/Formsnickare (Sverige)",
          intro:
            "Byggföretaget Andrama Bygg, i samband med ökningen av arbetsvolymen, söker att förstärka sitt team med betongarbetare/formsnickare.",

          requirements: {
            sectionTitle: "Kandidats krav:",
            list: [
              "Erfarenhet av formsättning (DOKA, PERI, plywood) och betonggjutning.",

              "Kännedom om betongvård enligt teknologiska krav.",

              "Erfarenhet av arbete med geodetiska mätinstrument är en fördel.",

              "Förmåga att läsa ritningar och arbeta enligt dem.",

              "Noggrannhet och högt ansvarstagande.",

              "Förmåga att arbeta i team och vilja att samarbeta.",

              "God planeringsförmåga och förmåga att utföra arbete i tid.",

              "Körkort kategori B är en fördel.",
            ],
          },
          responsibilities: {
            sectionTitle: "Arbetsuppgifter:",
            list: [
              "Utföra formsättning, betonggjutning och avformning enligt tekniska krav.",

              "Säkerställa kvalitet och noggrannhet i byggarbetet.",

              "Underhålla verktyg och utrustning.",

              "Samarbeta med andra teammedlemmar för att möta projektets tidsfrister.",

              "Följa säkerhetsstandarder och arbetsförhållanden på plats.",

              "Deltaga i planeringen av arbetet och beställning av material.",
            ],
          },
          offers: {
            sectionTitle: "Företaget erbjuder:",
            list: [
              "Konkurrenskraftig lön, beroende på erfarenhet och kvalifikationer, från 12 Euro/timme netto (i handen).",

              "Alla nödvändiga verktyg, arbetskläder och personliga skyddsutrustning för arbetet, anpassade till arbetsförhållanden.",

              "Betald bostad i Sverige.",

              "Betalda reseutgifter.",

              "Gratis transport till och från arbetsplatsen.",

              "Förberedelse av nödvändig dokumentation.",

              "Arbete enligt rotationsschema – 3 veckor på plats, 1 vecka ledig.",
            ],
          },
          endNote:
            "Intresserade kandidater ombeds att fylla i ansökningsformuläret eller skicka in sin ansökan tillsammans med ett CV till e-postadressen: job@andrama.se.",
        },
        {
          id: 3,
          positionTitle: "Armeringsspecialist (Sverige)",
          intro:
            "Byggföretaget Andrama Bygg, i respons på en ökning av arbetsbelastningen, bjuder in ansökningar till tjänsten som Armeringsspecialist.",
          requirements: {
            sectionTitle: "Kandidats krav:",
            list: [
              "Erfarenhet av olika typer av armeringsarbeten.",

              "Förmåga att läsa ritningar och arbeta enligt dem.",

              "Noggrannhet och högt ansvarstagande.",

              "Förmåga att arbeta i team.",

              "Ansvarstagande för de uppgifter som tilldelats och deras utförande, förmåga att organisera sitt arbete och planera deadlines.",

              "Körkort kategori B är en fördel.",
            ],
          },
          responsibilities: {
            sectionTitle: "Arbetsuppgifter:",
            list: [
              "Utföra armeringsarbete enligt ritningar.",

              "Säkerställa kvalitet och noggrannhet i byggarbetet.",

              "Underhålla verktyg och utrustning.",

              "Samarbeta med andra teammedlemmar för att möta projektets tidsfrister.",

              "Följa säkerhetsstandarder på arbetsplatsen.",

              "Deltaga i planeringen av arbetet och beställning av material.",
            ],
          },
          offers: {
            sectionTitle: "Företaget erbjuder:",
            list: [
              "Konkurrenskraftig lön, beroende på erfarenhet och kvalifikationer, från 12 Euro/timme netto (i handen).",

              "Alla nödvändiga verktyg, arbetskläder och personliga skyddsutrustning för arbetet, anpassade till arbetsförhållanden.",

              "Betald bostad i Sverige.",

              "Betalda reseutgifter.",

              "Gratis transport till och från arbetsplatsen.",

              "Förberedelse av nödvändig dokumentation.",

              "Arbete enligt rotationsschema – 3 veckor på plats, 1 vecka ledig.",
            ],
          },
          endNote:
            "Intresserade kandidater ombeds att fylla i ansökningsformuläret eller skicka in sin ansökan tillsammans med ett CV till e-postadressen: job@andrama.se.",
        },
      ],
      formData: {
        formTitle: "Ansökningsformulär",
        fullName: "Fullständigt namn",
        jobPosition: "Jobbtitel",
        email: "E-post",
        phone: "Telefon",
        file: "CV-uppladdning",
        coverLetter: "Personligt brev",
        submitButton: "Skicka ansökan",
      },
    },
  },
  submitSuccess: {
    message: "Thank you, your message has been sent.",
    button: "Back to home",
  },
  submitError: {
    message: "Ooops, something went wrong.",
    button: "Try again",
  },
  footer: {
    copyright: "© 2025 ANDRAMA Bygg AB. All rights reserved.",
  },
};

export const dataSvenska = {
  header: {
    about: "Om oss",
    contact: "Kontakta oss",
    projects: "Projekt",
    home: "Startsida",
    language: {
      english: {
        name: "English",
        img: "../images/EU-flag.png",
      },
      svenska: {
        name: "Svenska",
        img: "../images/SE-flag.png",
      },
    },
  },
  about: {
    title1: "Om ANDRAMA",
    title2: "Våra värderingar",
    subtitle1: "Prefabricering",
    subtitle2: "Betong",
    subtitle3: "Gipsskivor",
    description:
      "Vi är ett team av proffs med ganska stor erfarenhet i byggbranschen som har samlats för att uppnå bästa möjliga resultat i allt vi gör. Vi håller starkt fokus på alla faser av arbetet med början från den allra första - noggrann granskning av ritningar och hitta det mest effektiva sättet hur man uppnår bästa arbetsflöde vilket eliminerar eventuella förseningar av projektet eftersom det är en av de mest avgörande delarna för att ha ett framgångsrikt projekt.",
    specialize:
      "Våra huvudsakliga kompetenser är inom betong- och stålprefabricerade elementmontage och platsgjuten betong. För att uppfylla kundernas behov tillhandahåller vi även montering av gipsskivor och har till och med ett team för montering av ställningar, så att våra kunder inte behöver lägga resurser på att hitta andra företag för småsaker på plats. <span>Och vi tar ansvar på allvar.</span>",
    values:
      "Svenska. olestias totam est veritatis ea enim iure, consequatur quibusdam mollitia ipsam, ad quia? Optio voluptas veritatis alias nostrum aspernatur labore excepturi at tempore, aperiam mollitia, beatae.",
    prefab:
      "Svenska. Although prefabrication in construction industry reaches back to 1830's, it is gaining more and more popularity as a more safe, cost-saving, enhanced quality and increased productivity approach in construction industry. In Sweden, 84% of detached houses are prefabricated.",
    concrete:
      "Svenska. Molestias totam est veritatis ea enim iure, consequatur quibusdam mollitia ipsam, ad quia? Optio voluptas veritatis alias nostrum aspernatur labore excepturi at tempore, aperiam mollitia, beatae, maiores culpa quo. Temporibus eaque fugit in dolor nisi doloremque itaque. Iure, adipisci totam. Iste vel quo error, cupiditate officiis expedita fugit tempora quidem non nam voluptate consectetur architecto?",
    plasterboard:
      "Svenska.Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas ad enim, praesentium ipsa perspiciatis reiciendis consectetur hic rerum voluptate ex quam facere cum aperiam laboriosam saepe culpa, officia dolores harum? Optio, beatae autem animi tempora recusandae eius laudantium rem molestiae facilis vero ab quas laborum nihil magnam qui? Natus quod debitis quo aperiam facere.",
  },
  projects: {
    slides: [],
  },
  contact: {
    text1: "Alternativt kan du kontakta oss direkt via:",
    phone: "+46 (0) 703-52 19 19",
    contactEmail: "info@andrama.se",
    text2: "Eller skriv oss till våra sociala konton: ",
    fullName: "fullständiga namn",
    email: "email",
    message: "meddelande...",
    submit: "skicka in",
  },
  submitSuccess: {
    message: "Tack! Ditt meddelande har skickats.",
    button: "Gå tillbaka",
  },
  submitError: {
    message: "Oj, något gick fel.",
    button: "Försök igen",
  },
  footer: {
    copyright: "© 2024 ANDRAMA Bygg AB. Alla rättigheter förbehållna.",
  },
};
