import "./styles/Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import Logo from "../images/White_central_logo.png";
import { dataEnglish } from "../data.js";

const Footer = () => {
  return (
    <footer className="large">
      <div className="footer-top">
        <div className="footer-top-logo">
          <img src={Logo} loading="lazy" alt="Logo" />
        </div>
        <div className="item-group">
          <FontAwesomeIcon icon={faEnvelope} />
          <div>
            <span>Need support?</span>
            <p>info@andrama.se</p>
          </div>
        </div>
        <div className="item-group">
          <FontAwesomeIcon icon={faEnvelope} />
          <div>
            <span>Questions about invoices?</span>
            <p>faktura@andrama.se</p>
          </div>
        </div>
        <div className="item-group">
          <FontAwesomeIcon icon={faGlobe} />
          <div>
            <span>Location </span>
            <p>103 94 Stockholm, BOX 7665</p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="bottom-left">
          <img loading="lazy" src={Logo} alt="Small logo" />
        </div>
        <div className="bottom-right">
          <div className="item-group">
            <h3>Quick links</h3>
            <nav>
              <ul>
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/about"}>About</Link>
                </li>
                <li>
                  <Link to={"/projects"}>Projects</Link>
                </li>
                <li>
                  <Link to={"/vacancies"}>Vacancies</Link>
                </li>{" "}
                <li>
                  <Link to={"/contact"}>Contact Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="item-group">
            <h3>Social media</h3>
            <div className="footer-social">
              <a
                href="https://www.linkedin.com/company/andrama-bygg/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
                Linkedin
              </a>
              <a
                href="https://www.facebook.com/andramabygg"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyrights">
        <p>{dataEnglish?.footer.copyright}</p>
      </div>
    </footer>
  );
};

export default Footer;
